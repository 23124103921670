import { useState } from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Icon, Text } from "@/app/components";
import { Button } from "@/app/components";
import { Select } from "@/app/components/input";
import { SearchWithCallback } from "@/app/components/input/search-with-callback.tsx";
import { Company } from "@/app/hooks";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { SentimentAttributeModal } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-modal.tsx";
import modalStore from "@/app/stores/modal.store";

export type SentimentAttribute =
  | "excitement_opinion_scale"
  | "vision_opinion_scale"
  | "market_opinion_scale"
  | "product_opinion_scale"
  | "team_opinion_scale"
  | "fundraising_opinion_scale"
  | "timing_opinion_scale"
  | "fit_opinion_scale"
  | "opportunity_opinion_scale";

export const sentimentAttributes: {
  value: SentimentAttribute;
  label: string;
}[] = [
  { label: "Excitement", value: "excitement_opinion_scale" },
  { label: "Vision", value: "vision_opinion_scale" },
  { label: "Market", value: "market_opinion_scale" },
  { label: "Product", value: "product_opinion_scale" },
  { label: "Team", value: "team_opinion_scale" },
  { label: "Fundraising", value: "fundraising_opinion_scale" },
  { label: "Timing", value: "timing_opinion_scale" },
  { label: "Fit", value: "fit_opinion_scale" },
  { label: "Opportunity", value: "opportunity_opinion_scale" },
  // { label: "Expected Return", value: "expected_return" }, // todo disabled in LOC-871 until implemented
];

export type SentimentFieldSelectorProps = {
  value: SentimentAttribute;
  onChange: (value: SentimentAttribute) => void;
};

export const SentimentFieldSelector = ({ onChange, value: selectedValue }: SentimentFieldSelectorProps) => {
  const { isLaptop } = useBreakpoints();
  const [maximised, setMaximised] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  function getLabelForValue(value: SentimentAttribute): string | undefined {
    const attribute = sentimentAttributes.find((attr) => attr.value === value);
    return attribute?.label;
  }

  if (!isLaptop) {
    return (
      <div>
        <Button
          onClick={() =>
            modalStore.open(SentimentAttributeModal, { props: { options: sentimentAttributes, onChange: onChange } })
          }
          variant={"tertiary"}
          size={"sm"}
          className={"h-9 rounded-sm bg-neutral-100 text-[14px] font-[500] text-[#464646]"}
        >
          {getLabelForValue(selectedValue)}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn("mt-2 flex items-center gap-2")}>
      <p className={"mr-4 text-[20px] font-semibold"}>Metrics</p>

      {isLaptop && (
        <Select<{ value: string; label: string }>
          className={
            "min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-500"
          }
          inputClassName={"text-[14px] font-semibold mr-3"}
          buttonClassName={"px-3 py-2"}
          optionsClassName={"text-[14px] font-medium w-36 no-scrollbar"}
          value={selectedValue}
          options={sentimentAttributes}
          onChange={({ value }) => onChange(value)}
        />
      )}

      {/*<p className={"mx-6 text-[20px] font-semibold"}>Compare</p>*/}

      {/*{isLaptop && (*/}
      {/*  <SearchCompany*/}
      {/*    searchBarClassName={*/}
      {/*      "min-w-fit !h-fit px-3 py-2 !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer"*/}
      {/*    }*/}
      {/*    inputClassName={"text-[14px] !rounded-none !px-10 !h-fit !w-fit hover:cursor-pointer"}*/}
      {/*    companySearchType={"lg"}*/}
      {/*    showEmptyState={false}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};
