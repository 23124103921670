import { useAuthContext } from "@/app/contexts/auth";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Checkbox, Icon, Text } from "app/components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const items = [
  "Easily search through PCG’s collective network - people and organisations",
  "Apply advanced filters to PCG’s collective network to create bespoke lists of people or organisations (follow-on funding lists, event invite lists, talent, etc)" +
    "Get more insight on people and organisations in our network via 3rd party data enrichment",
  "See connection strength between PCG team members and people/organisations in our network (number of email interactions, meetings, etc)",
  "Automatic sentiment form creation using calendar integration",
  "Network sourcing statistics and attribution",
];

const contactsLookupQuery = gql`
  query networkContactsLookup($q: String, $sort: String, $filters: String) {
    contacts: networkContactsLookup(q: $q, sort: $sort, filterS: $filters) {
      nodes {
        email
        name
        tags
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
        contactType
      }
    }
  }
`;

const numberFormat = Intl.NumberFormat("en-GB");

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});
export function NetworkDashboard() {
  const { myRoles, loaded } = useAuthContext();

  if (!loaded) return null;
  // TODO: make it enum & not a string !?
  if (myRoles.includes("USER_ROLE_NETWORK")) {
    return <NetworkDashboardBeta />;
  }

  return <NetworkDashboardComing />;
}

enum Column {
  Contact = "contact",
  Connectivity = "connectivity",
  LIncoming = "lincoming",
  LOutgoing = "loutgoing",
  NIncoming = "nincoming",
  NOutgoing = "noutgoing",
  Connections = "connections",
}

enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

type ColumnMapValue = {
  title: string;
  extraTheadClasses?: string;
};

const columns = new Map<Column, ColumnMapValue>([
  [Column.Contact, { title: "Contact", extraTheadClasses: "rounded-tl-sm" }],
  [Column.Connectivity, { title: "Connectivity", extraTheadClasses: "text-center" }],
  [Column.LIncoming, { title: "Last Incoming", extraTheadClasses: "text-center" }],
  [Column.LOutgoing, { title: "Last Outgoing", extraTheadClasses: "text-center" }],
  [Column.NIncoming, { title: "# Incoming" }],
  [Column.NOutgoing, { title: "# Outgoing" }],
  [Column.Connections, { title: "Connections", extraTheadClasses: "rounded-tr-sm" }],
]);

const ColumnValues = Object.values(Column);
const SortDirectionValues = Object.values(SortDirection);
const defaultColumn = Column.Connectivity;
const defaultSortDirection = SortDirection.Desc;

function sortPairValueFromString(v?: string | null): [Column, SortDirection] {
  if (!v) return [defaultColumn, defaultSortDirection];
  const [desiredColumn, desiredSortDirection] = v.split("_");
  const resolvedColumn = ColumnValues.includes(desiredColumn as Column) ? (desiredColumn as Column) : defaultColumn;
  const resolvedSortDirection = SortDirectionValues.includes(desiredSortDirection as SortDirection)
    ? (desiredSortDirection as SortDirection)
    : defaultSortDirection;

  return [resolvedColumn, resolvedSortDirection];
}

function sortPairValueToString(column: Column, sortDirection: SortDirection): string {
  return [column, sortDirection].join("_");
}

type TableHeaderCellProps = {
  column: Column;
  sortPair: [Column, SortDirection];
};

function TableHeaderCell(props: TableHeaderCellProps) {
  const columnInfo = columns.get(props.column);
  if (!columnInfo) throw new Error(`Unsupported column ${props.column}`);
  let classes = "px-4 py-4 font-medium text-neutral text-left bg-white whitespace-nowrap cursor-pointer select-none ";
  if (columnInfo.extraTheadClasses) {
    classes += columnInfo.extraTheadClasses;
  }

  let nextDirection: SortDirection;
  let imgStyle;
  if (props.sortPair[0] === props.column) {
    if (props.sortPair[1] === SortDirection.Asc) {
      nextDirection = SortDirection.Desc;
      imgStyle = { transform: "scaleY(-1)" };
    } else {
      imgStyle = {};
      nextDirection = SortDirection.Asc;
    }
  } else {
    nextDirection = SortDirection.Asc;
    imgStyle = { visibility: "hidden" };
  }
  const nextSort = sortPairValueToString(props.column, nextDirection);

  return (
    <th className={classes} data-column-next-sort={nextSort}>
      {columnInfo.title}{" "}
      <img
        className="inline transition-transform duration-200 ease-in-out"
        style={imgStyle}
        src="/assets/icons/arrow-sort-desc.svg"
      />
    </th>
  );
}

type TableHeaderProps = {
  sortPair: [Column, SortDirection];
  onTableHeaderClick: (e) => void;
};

function TableHeader(props: TableHeaderProps) {
  return (
    <thead className="hidden shadow-sm @7xl:table-row-group sticky top-[127px] lg:top-[65px] z-10">
      <tr className=" leading-tight" onClick={props.onTableHeaderClick}>
        {[...columns].map(([column]) => (
          <TableHeaderCell key={column} column={column} sortPair={props.sortPair} />
        ))}
      </tr>
    </thead>
  );
}

type TableProps = {
  sortPair: [Column, SortDirection];
  handleTableHeaderClick: (e) => void;
  handleListClick: (e) => void;
  data: any;
};

function Table(props: TableProps) {
  const { sortPair, handleTableHeaderClick, handleListClick, data } = props;
  return (
    <table className="divide-y flex w-full @7xl:table text-[14px]">
      <TableHeader sortPair={sortPair} onTableHeaderClick={handleTableHeaderClick} />
      <tbody
        className="grid grid-cols-1 gap-2 @3xl:grid-cols-2 @5xl:grid-cols-3 @7xl:table-row-group @7xl:divide-y @7xl:bg-white @7xl:gap-0"
        onClick={handleListClick}
      >
        {data?.contacts?.nodes?.map((contact) => <Contact key={contact.email} {...contact} />)}
      </tbody>
      <tfoot className="hidden @7xl:table-row-group">
        <tr>
          <th colSpan={7} className="p-2 rounded-b-sm bg-white"></th>
        </tr>
      </tfoot>
    </table>
  );
}

enum FlagFilterKey {
  EngagedOnly = "engaged_only",
}

enum FilterType {
  Flag = "flag",
}

type FlagFilter = {
  key: FlagFilterKey.EngagedOnly;
  value: boolean;
  type: FilterType.Flag;
};

type Filters = Map<FlagFilterKey, FlagFilter>;

function filtersToStr(filters: Filters): string {
  const res: string[] = [];
  for (const [_, filter] of filters) {
    if (filter.type === "flag" && filter.value === true) {
      res.push(filter.key);
    }
  }
  return res.join("-");
}

function filtersFromStr(str?: string | null): Filters {
  const filters: Filters = new Map();
  if (!str) {
    return filters;
  }

  const splited = str.split("-");
  for (const maybeFilter of splited) {
    if (Object.values(FlagFilterKey).includes(maybeFilter as FlagFilterKey)) {
      const filterKey = maybeFilter as FlagFilterKey;
      filters.set(filterKey, { key: filterKey, value: true, type: FilterType.Flag });
    }
  }

  return filters;
}

export function NetworkDashboardBeta() {
  const [params, setParams] = useSearchParams();
  const [q, setQ] = useState(params.get("q") || "");
  const [debounceQ, setDebounceQ] = useState("");
  const [initialData, setInitialData] = useState(!!q);
  const selectedFilters = filtersFromStr(params.get("filter"));
  const [filters, setFilters] = useState(selectedFilters);
  const [sortPair, setSortPair] = useState<[Column, SortDirection]>(sortPairValueFromString(params.get("sort")));
  const [lookup, { data, loading, previousData }] = useLazyQuery(contactsLookupQuery, {
    variables: { q, order: sortPairValueToString(...sortPair) },
  });
  const navigate = useNavigate();
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceQ(q);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [q]);

  useEffect(() => {
    const sortPairString = sortPairValueToString(...sortPair);
    const filtersString = filtersToStr(filters);
    if (debounceQ) {
      const searchParams = new URLSearchParams(params);
      searchParams.set("q", debounceQ);
      searchParams.set("sort", sortPairString);
      if (filtersString.length) {
        searchParams.set("filter", filtersString);
      } else {
        searchParams.delete("filter");
      }
      setParams(searchParams, { replace: true });
      lookup({
        variables: { q: debounceQ, sort: sortPairString, filters: filtersString },
      });
    }
  }, [debounceQ, lookup, params, sortPair, filters]);

  useEffect(() => {
    if (initialData) return;
    if (data?.contacts?.nodes?.length) setInitialData(true);
  }, [data]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setQ(e.target.value), []);

  // PERF: have one onClick handler for huge list instead of every element
  const handleListClick = useCallback(
    (e) => {
      const clickedContact = e.target?.closest("[data-contact-email]")?.getAttribute("data-contact-email");
      if (clickedContact) {
        e.preventDefault();
        navigate(
          `/network/contact/${clickedContact}?fq=${debounceQ}&fsort=${sortPairValueToString(...sortPair)}&ffilter=${filtersToStr(filters)}`
        );
      }
    },
    [navigate, debounceQ, sortPair, filters]
  );

  const handleTableHeaderClick = useCallback((e) => {
    const nextPairString = e.target?.closest("[data-column-next-sort]")?.getAttribute("data-column-next-sort");
    if (nextPairString) {
      const nextPair = sortPairValueFromString(nextPairString);
      e.preventDefault();
      setSortPair(nextPair);
    }
  }, []);

  const handleEngagedClick = useCallback((e) => {
    const val = !!e.target?.checked;
    const incomingFilters = new Map(filters);
    incomingFilters.set(FlagFilterKey.EngagedOnly, {
      key: FlagFilterKey.EngagedOnly,
      type: FilterType.Flag,
      value: val,
    });
    setFilters(incomingFilters);
  }, []);

  let inputWrapperClasses: string;
  let inputClasses: string;
  let inputTitleClasses: string;
  let inputInnerWrapperClasses: string;
  let engagedClasses: string;

  const displayedData = data || previousData;
  if (!displayedData?.contacts?.nodes?.length && !initialData) {
    inputWrapperClasses = "flex flex-col items-center justify-center flex-grow";
    inputClasses = "my-2 w-full rounded-sm border border-neutral-300 px-3 py-2 pl-10 mb-0";
    inputTitleClasses = "text-[24px] font-semibold mb-2 text-center";
    inputInnerWrapperClasses = "w-full max-w-[600px]";
    engagedClasses = "hidden";
  } else {
    inputWrapperClasses = "sticky mb-1 p-0 @7xl:mb-0 @7xl:py-2 top-[50px] lg:top-[0px]  top-0 z-10 bg-[#f0f0f0]";
    inputClasses = "my-2 w-full rounded-sm border border-neutral-300 px-3 py-2 pl-10 mb-0";
    inputTitleClasses = "hidden";
    inputInnerWrapperClasses = "w-full";
    engagedClasses = "absolute top-[-1px] right-[20px]";
  }

  const inputIconType = loading ? "Loader" : "Search";
  const labelStyle = loading ? "absolute top-[19px] left-[11px]" : "absolute top-[21px] left-[13px]";
  return (
    <div className="@container font-barlow flex w-full mt-[50px] lg:mt-0 max-w-[100%] h-width">
      <div className="flex flex-col p-4 @7xl:p-8 w-full">
        <div className={inputWrapperClasses}>
          <div className={inputTitleClasses}>Network. Beta.</div>
          <div className={inputInnerWrapperClasses}>
            <div className="relative w-full">
              <label htmlFor="network-search-input" className={labelStyle}>
                <Icon type={inputIconType} width={20} />
              </label>
              <input
                id="network-search-input"
                type="text"
                autoComplete="off"
                autoFocus
                onChange={handleInputChange}
                className={inputClasses}
                value={q}
                placeholder="Search by name, email address or domain."
              />
              <div className={engagedClasses}>
                <Checkbox
                  checked={filters.get(FlagFilterKey.EngagedOnly)?.value}
                  onClick={handleEngagedClick}
                  label={"Engaged Only"}
                  containerClassName={"my-2"}
                  labelClassName={"text-black font-medium"}
                />
              </div>
            </div>
          </div>
        </div>
        {initialData ? (
          <Table
            data={displayedData}
            sortPair={sortPair}
            handleTableHeaderClick={handleTableHeaderClick}
            handleListClick={handleListClick}
          />
        ) : null}
      </div>
    </div>
  );
}

type ContactProps = {
  email: string;
  name: string[];
  tags: string[];
  totalTo: number;
  totalFrom: number;
  lastTo: string;
  lastFrom: string;
  connections: string[];
  key?: string;
  connectionStrength: number;
};

function formatDate(dateTime?: string): string {
  if (!dateTime) return "—";

  // HACK: we expect data w/ not timezone here
  // but we know it Zulu so gonna treat it like one
  return dateTimeFormat.format(new Date(`${dateTime}Z`));
}

function Contact(props: ContactProps) {
  const fakeHeaderClasses = "font-medium text-neutral text-[12px] @7xl:hidden";

  // NOTE: connectionStrength is a real number [0,1]
  // we need to show low/med/high baced on linear percentile value
  // i.e. [0-33] [33-66] [66-100]
  const connectivityIcons = ["low", "medium", "high"];
  const connectivityIconType = connectivityIcons[Math.min(2, Math.floor((props.connectionStrength || 0) * 3))];
  return (
    <tr
      className="flex flex-wrap p-2 rounded-sm bg-white card w-full overflow-hidden  @7xl:table-row cursor-pointer relative @7xl:p-0"
      data-contact-email={props.email}
      key={props.email}
    >
      <td className="flex flex-col p-2 mr-7 @7xl:w-[300px] overflow-hidden @7xl:px-2 @7xl:p-4 w-full  @7xl:table-cell @7xl:mr-0 @7xl:pl-4">
        <div className="line-clamp-1 @7xl:w-[300px] overflow-hidden text-ellipsis break-all w-auto font-semibold leading-normal text-black">
          {props.email}
        </div>
        <div className="line-clamp-1  @7xl:w-[300px]  font-[500] overflow-hidden text-ellipsis break-all text-neutral-700 sm:text-sm">
          {props.name.join(", ")}
        </div>
      </td>
      <td className="flex absolute top-6 right-6 @3xl:top-4 @3xl:right-4 @7xl:px-2 @7xl:p-4 @7xl:text-center @7xl:w-[1px] @7xl:table-cell @7xl:static">
        <img className="inline" src={`/assets/icons/connectivity-${connectivityIconType}.svg`} />
      </td>
      <td className="flex flex-col p-2 @7xl:table-cell w-1/4 @7xl:px-2 @7xx:p-4 text-black @7xl:text-center whitespace-nowrap @7xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Incoming</div>
        <div>{formatDate(props.lastFrom)}</div>
      </td>
      <td className="flex flex-col p-2 @7xl:table-cell w-1/4 @7xl:px-2 @7xl:p-4 text-black @7xl:text-center whitespace-nowrap @7xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Outgoing</div>
        <div>{formatDate(props.lastTo)}</div>
      </td>
      <td className="flex flex-col p-2 w-1/4 @7xl:pl-4 @7xl:p-4 whitespace-nowrap @7xl:w-[1px] @7xl:table-cell ">
        <div className={fakeHeaderClasses}># Incoming</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-incoming.svg" />
          {numberFormat.format(props.totalFrom)}
        </div>
      </td>{" "}
      <td className="flex flex-col p-2 w-1/4 @7xl:pl-4 @7xl:p-4 whitespace-nowrap @7xl:w-[1px] @7xl:table-cell">
        <div className={fakeHeaderClasses}># Outgoing</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" />
          {numberFormat.format(props.totalTo)}
        </div>
      </td>
      <td className="flex flex-col p-2 w-full @7xl:pl-4 @7xl:p-4 @7xl:table-cell @7xl:w-auto">
        <div className={fakeHeaderClasses}>Connections</div>
        <div className="line-clamp-2  font-[500] overflow-hidden text-ellipsis text-neutral-700 sm:text-sm leading-normal">
          <span className="bg-neutral-100 px-2 py-1 rounded-sm text-center text-xs text-neutral w-auto">
            {props.connections.length}
          </span>{" "}
          {props.connections.join(", ")}
        </div>
      </td>
    </tr>
  );
}

export const NetworkDashboardComing = ({}) => {
  return (
    <div className={"flex w-full flex-col items-center justify-center p-8 pt-[10dvh] lg:pt-0"}>
      <Text text={"🚀 Exciting news! Network coming soon!"} type={"h5"} className={"mb-8 text-center"} />
      <Text
        text={
          "Network is on the way to our web app! While it's not quite here yet, here's a glimpse of what you can expect. Stay tuned for updates!"
        }
        type={"subtitle"}
        color={"text-neutral-700"}
        className={"text-center"}
      />
      <div className={"mt-8 space-y-3.5 lg:w-[50%]"}>
        {items.map((item, index) => (
          <div
            className={
              "flex cursor-pointer select-none items-center rounded-md bg-white px-3 py-5 shadow-sm transition-transform duration-200 ease-in-out hover:scale-[1.03] lg:p-6"
            }
          >
            <Text text={String(index + 1)} className={"mr-2 rounded-sm bg-neutral-100 px-3 py-2"} />
            <Text text={item} color={"text-neutral-600"} />
          </div>
        ))}
      </div>
    </div>
  );
};
