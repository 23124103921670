import React, { useContext } from "react";

import { curveMonotoneX } from "@visx/curve";
import { GlyphDot } from "@visx/glyph";
import { LinearGradient } from "@visx/gradient";
import { getSeededRandom, getRandomNormal } from "@visx/mock-data";
import genStats, { Stats } from "@visx/mock-data/lib/generators/genStats";
import { PatternLines } from "@visx/pattern";
import { useParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { BoxPlot } from "@visx/stats";
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,
  DataContext,
  GlyphProps,
  lightTheme,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import cx from "classnames";
import { quantileSorted } from "d3-array";
import { curveCardinal, line } from "d3-shape";
import { DateTime } from "luxon";

import { cn, extractFormCategory } from "@/lib/utils.ts";

import { colors } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import {
  SentimentAttribute,
  sentimentAttributes,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-selector.tsx";

const accesors = {
  xAccessor: (data) => data.date,
  yAccessor: (data) => data.metric,
};

const seededRandom = getSeededRandom(0.1);
const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(5, 1);

function ViolinPlot({
  left = 0,
  top = 0,
  className,
  data,
  width = 10,
  count = (d) => d.count,
  value = (d) => d.value,
  valueScale,
  horizontal,
  children,
  ...restProps
}) {
  const center = (horizontal ? top : left) + width / 2;
  const binCounts = data.map((bin) => count(bin));
  const widthScale = scaleLinear<number>({
    range: [0, width / 2],
    round: true,
    domain: [0, Math.max(...binCounts)],
  });

  let path = "";

  if (horizontal) {
    const topCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center - (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const bottomCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center + (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const topCurvePath = topCurve(data) || "";
    const bottomCurvePath = bottomCurve([...data].reverse()) || "";
    path = `${topCurvePath} ${bottomCurvePath.replace("M", "L")} Z`;
  } else {
    const rightCurve = line()
      .x((d) => center + (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const leftCurve = line()
      .x((d) => center - (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const rightCurvePath = rightCurve(data) || "";
    const leftCurvePath = leftCurve([...data].reverse()) || "";
    path = `${rightCurvePath} ${leftCurvePath.replace("M", "L")} Z`;
  }
  if (children) return <>{children({ path })}</>;
  return <path className={cx("visx-violin", className)} d={path} {...restProps} />;
}

const renderGlyph = ({
  x,
  y,
  datum,
  size,
  onPointerMove,
  onPointerOut,
  onPointerUp,
}: GlyphProps<{ x: string; y: number }>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { xScale, yScale, innerWidth } = useContext(DataContext) || {};
  const width = 22;
  const violinWidth = width * 3;

  let yScaleCopy = function (d) {
    return yScale(d) - yScale(datum.metric);
  };

  yScaleCopy = Object.assign(yScaleCopy, { range: yScale.range });

  return (
    <>
      {/*<ViolinPlot*/}
      {/*  // left={xScale(datum.date)}*/}
      {/*  left={-width / 2}*/}
      {/*  data={datum.bins}*/}
      {/*  valueScale={(d) => yScale(d) - yScale(datum.metric)}*/}
      {/*  width={width}*/}
      {/*  fill={"rgba(233,54,107,0.2)"}*/}
      {/*/>*/}

      <BoxPlot
        min={datum.boxPlot.min}
        max={datum.boxPlot.max}
        left={-width / 2}
        firstQuartile={datum.boxPlot.q25}
        thirdQuartile={datum.boxPlot.q75}
        median={datum.boxPlot.q50}
        boxWidth={width}
        fill="url(#area-gradient)"
        fillOpacity={0.2}
        stroke={colors.primary.DEFAULT}
        strokeWidth={1}
        valueScale={yScaleCopy}
      />
      <ViolinPlot
        // left={xScale(datum.date)}
        left={-violinWidth / 2}
        data={datum.bins}
        valueScale={yScaleCopy}
        width={violinWidth}
        fillOpacity={0.1}
        fill="url(#area-gradient)"
      />
      <GlyphDot
        left={x}
        top={y}
        fill="url(#area-gradient)"
        stroke={"white"}
        strokeWidth={1}
        fillOpacity={0.8}
        r={size / 2 + 1}
        {...handlers}
      />
    </>
  );
};

function SentimentChart({ data, analytics, width, height, setActiveTooltip }) {
  return (
    <XYChart
      xScale={{ type: "band" }}
      yScale={{ type: "linear", domain: [analytics.min - 0.5, analytics.max + 0.5], zero: false, round: true }}
      theme={lightTheme}
      width={width}
      margin={{ top: 20, right: 20, bottom: 20, left: 30 }}
      height={height}
      onPointerDown={({ datum }) => {
        const item = datum?.form;
        opportunityModal({
          id: `sentiment_details`,
          platform: window.innerWidth <= 976 ? "mobile" : "web",
          action: "Sentiment",
        });

        opportunitiesStore.setModalState("sentiment_details", {
          form: item,
        });
      }}
    >
      <LinearGradient id="area-gradient" from={colors.secondary.DEFAULT} to={colors.primary.DEFAULT} />
      <PatternLines
        id="violinLines"
        height={3}
        width={3}
        stroke="#E9366B"
        strokeWidth={1}
        // fill="rgba(0,0,0,0.3)"
        orientation={["horizontal"]}
      />
      <AnimatedGrid
        key={`grid-xy`} // force animate on update
        rows={false}
        columns={false}
        strokeDasharray={"8"}
        lineStyle={{ stroke: colors.neutral[400], strokeWidth: 1 }}
      />
      <AnimatedAxis
        key={`time-axis`}
        orientation="bottom"
        axisLineClassName={"stroke-neutral-400"}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{
          className: cn("text-xss font-semibold text-neutral-400"),
        }}
      />
      <AnimatedAxis
        key={`right-axis`}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{ className: cn("text-xss font-semibold") }}
        orientation={"left"}
      />
      {/*{data.map((datum) => (*/}
      {/*  <ViolinGlyph datum={datum} />*/}
      {/*))}*/}

      {/*<AnimatedLineSeries*/}
      {/*  dataKey="line"*/}
      {/*  strokeWidth={3.5}*/}
      {/*  stroke="url(#area-gradient)"*/}
      {/*  fillOpacity={0.9}*/}
      {/*  data={data}*/}
      {/*  {...accesors}*/}
      {/*  curve={curveMonotoneX}*/}
      {/*/>*/}
      <AnimatedGlyphSeries renderGlyph={renderGlyph} dataKey="line" data={data} {...accesors} />

      <Tooltip
        applyPositionStyle={true}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showHorizontalCrosshair
        showSeriesGlyphs
        horizontalCrosshairStyle={{ strokeDasharray: "8" }}
        renderGlyph={(props) => (
          <>
            <GlyphDot {...props} r={4} fill="url(#area-gradient)" stroke={"white"} />
          </>
        )}
        renderTooltip={() => null}
        // renderTooltip={({ tooltipData, showTooltip }) => {
        //   setActiveTooltip(tooltipData.nearestDatum.datum.id);
        //
        //   return (
        //     <div className={""}>
        //       <p
        //         className={"text-xss font-semibold text-neutral-700"}
        //       >{`${extractFormCategory(tooltipData.nearestDatum.datum.form)}`}</p>
        //       <p className={"text-xss font-semibold text-neutral-700"}>
        //         {normaliseDate(accesors.xAccessor(tooltipData.nearestDatum.datum))}
        //       </p>
        //       <p className="mt-2">{accesors.yAccessor(tooltipData.nearestDatum.datum)?.toFixed(2)}</p>
        //       <p>{tooltipData.nearestDatum.datum.people.join(", ")}</p>
        //     </div>
        //   );
        // }}
      />
    </XYChart>
  );
}

export function SentimentHistory({
  sentiment,
  referenceField,
  setActiveTooltip,
}: {
  sentiment: any; // todo
  referenceField: SentimentAttribute;
  setActiveTooltip: any; // todo
}) {
  const chronologicalForms = [...sentiment].filter(({ responses }) => responses?.length > 0).reverse();
  const labels = chronologicalForms.map((form) => DateTime.fromISO(form.createdAt).toFormat("yyyy-MM-dd"));

  const boxFormData = chronologicalForms.map((form) =>
    form?.responses?.map((response) => response.answers.find((answer) => answer.field_id === referenceField)?.value),
  );

  const data = sentimentAttributes.map((item) => {
    const responseArray = genStats(1, randomNormal, () => 2 * seededRandom());

    return {
      metric: responseArray[0].binData[Math.floor(Math.random() * responseArray[0].binData.length)].value,
      id: "1234",
      form: item,
      date: item.label,
      boxPlot: {
        min: responseArray[0].boxPlot.min,
        q25: responseArray[0].boxPlot.firstQuartile,
        q50: responseArray[0].boxPlot.median,
        q75: responseArray[0].boxPlot.thirdQuartile,
        max: responseArray[0].boxPlot.max,
      },
      bins: responseArray[0].binData,
    };
  });

  const analytics = {
    min: Math.min(...data.map((item) => item.boxPlot.min)),
    max: Math.max(...data.map((item) => item.boxPlot.max)),
  };

  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });

  return (
    <div ref={parentRef} className={"h-[33dvh] flex-1 basis-1/3 lg:min-h-[400px]"}>
      <SentimentChart
        data={data}
        analytics={analytics}
        width={width}
        height={height}
        setActiveTooltip={setActiveTooltip}
      />
    </div>
  );
}
