import React, { Suspense } from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Card, IconType, Loader, TabBar } from "@/app/components";
import { EmptySection } from "@/app/screens/opportunities/opportunity/components";
import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";
import { SentimentTable } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-table.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { SentimentBoxPlot } from "./sentiment-box-plot.tsx";
import { SentimentChart } from "./sentiment-chart.tsx";
import { SentimentContextProvider, SentimentState, SentimentTab, useSentimentContext } from "./sentiment-context.tsx";

const useTabs = () => {
  const { isPhone } = useBreakpoints();

  const viewTypeTabs: {
    value: SentimentTab;
    label: string;
    icon?: IconType;
  }[] = [
    { value: SentimentTab.Chart, label: "Chart View", icon: "LineChart" },
    { value: SentimentTab.Table, label: "Table View", icon: "Table" },
    // { value: SentimentTab.Bar, label: "Bar View", icon: "Table" },
  ];
  return isPhone ? viewTypeTabs.map(({ icon, ...tab }) => tab) : viewTypeTabs;
};

const SentimentContent = () => {
  const { company } = useOpportunityContext();
  const { state, activeTab, setActiveTab } = useSentimentContext();
  const viewTypeTabs = useTabs();

  if (state === SentimentState.no_data || !company) {
    return <EmptySection text={"No Sentiment Forms Filled"} />;
  }

  const sentimentData = company?.sentiment?.nodes || [];

  return (
    <section className={cn("py-1.5")}>
      <Suspense fallback={<Loader />}>
        <Card className={cn("relative flex flex-col items-start justify-start overflow-x-hidden lg:p-6 lg:pt-4")}>
          {sentimentData?.length > 0 ? (
            <>
              <TabBar
                tabs={viewTypeTabs}
                selectedTab={activeTab}
                onClick={(idx) => setActiveTab(viewTypeTabs[idx].value)}
                containerClassName={cn("absolute right-5 top-5 z-10")}
                elementProps={{
                  icon: {
                    className: "lg:h-9 lg:w-9 h-7 w-7 bg-neutral-100 p-1 rounded-sm mx-0",
                  },
                }}
              />

              {activeTab === SentimentTab.Chart && <SentimentChart sentimentData={sentimentData} />}
              {activeTab === SentimentTab.Bar && <SentimentBoxPlot sentimentData={sentimentData} />}
              {activeTab === SentimentTab.Table && <SentimentTable sentimentData={sentimentData} />}
            </>
          ) : (
            <EmptySection text={"No Sentiment Data Found"} />
          )}
        </Card>
      </Suspense>
    </section>
  );
};

export const Sentiment = () => {
  return (
    <SentimentContextProvider>
      <SentimentContent />
    </SentimentContextProvider>
  );
};
