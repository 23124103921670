import { useBreakpoints } from "@/lib/hooks";
import { cn, parseCompanyDetails } from "@/lib/utils";

import { Card, Text } from "@/app/components";
import { CompanyImg } from "@/app/components/avatar/company-image";
import { Company } from "@/app/types/company.ts";

import { TitleWithSignal } from "../title.tsx";

import { CardAccess } from "./card-access";
import { CardActions } from "./card-actions";
import { CardEngagement } from "./card-engagement";
import { CardExcitement } from "./card-excitement";
import { Tags } from "./tags";

export interface CompanyCardProps {
  company: Company;
  index: number;
  onClick?: () => void;
}

export const CompanyCard = ({ company, index: idx, onClick }: CompanyCardProps) => {
  const { isBigTablet } = useBreakpoints();
  const {
    id,
    name,
    image,
    city,
    country,
    badges,
    industries,
    tagline,
    assignees,
    round,
    portCo,
    badgeSignals,
    trelloSource,
    sentiments,
    previouslyMet,
    comments,
    attachments,
    basecamp,
  } = parseCompanyDetails(company);

  return (
    <Card
      key={`${id}_${idx}`}
      onClick={onClick}
      data-cy="opportunity-card"
      className={cn(
        "group relative min-h-[15dvh] cursor-pointer overflow-hidden bg-white p-0.5 shadow-surface transition-shadow",
      )}
    >
      {/* gradient border */}
      <div className="absolute left-0 top-0 size-full bg-gradient-to-r from-primary-400 to-secondary-400 opacity-0 transition-all ease-in group-active:opacity-100 lg:group-hover:opacity-100" />

      <div className="relative h-full rounded-[10px] bg-white p-3 hover:transition-all sm:p-6 sm:pb-4 lg:p-4.5 2xl:p-6">
        <div className={"relative flex w-full items-center"}>
          <CompanyImg src={image} />
          <div className={"ml-4 text-left"}>
            <TitleWithSignal
              title={name}
              signals={badgeSignals}
              showSignals={true}
              isPortco={!!portCo}
              name={trelloSource?.list?.name}
            />
          </div>
          <CardActions containerClassName="absolute right-0 top-0 transition-all flex" company={company} />
        </div>
        <Text
          text={tagline || "..."}
          noOfLines={isBigTablet ? 1 : 2}
          weight={"medium"}
          className="mt-3 text-neutral-700 md:text-sm lg:text-[12px] 2xl:text-[14px]"
        />
        <Tags
          city={city}
          country={country}
          badges={badges}
          round={round}
          portCo={portCo}
          industries={industries}
          className="my-3 lg:mb-6 lg:mt-3"
        />
        <div className="flex gap-0 md:grid md:grid-cols-5">
          <CardExcitement sentiments={sentiments} previouslyMet={previouslyMet} isPortco={!!portCo} />

          <CardEngagement
            comments={comments}
            attachments={attachments}
            previouslyMet={previouslyMet}
            isPortco={!!portCo}
          />

          <CardAccess assignees={assignees} basecamp={basecamp} previouslyMet={previouslyMet} isPortco={!!portCo} />
        </div>
      </div>
    </Card>
  );
};
