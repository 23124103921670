import { useMemo } from "react";

import { Card } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { useUserHighlights } from "./hooks/use-user-highlightes.tsx";

export type BoxType = "Reviews" | "Follows" | "Sentiments" | "Forwards" | "Updates" | "Feedbacks";

const colorScheme = {
  Reviews: "bg-highlightsCard-pale-blue",
  Follows: "bg-highlightsCard-light-yellow",
  Sentiments: "bg-highlightsCard-lavender",
  Forwards: "bg-highlightsCard-mint-green",
  Updates: "bg-highlightsCard-peach",
  Feedbacks: "bg-highlightsCard-light-pink",
  Access: "bg-highlightsCard-sky-blue",
  Insight: "bg-highlightsCard-light-green",
  "Capital Concentration": "bg-highlightsCard-light-green",
  Engagement: "bg-highlightsCard-pink",
};

export const UserHighlightSection = () => {
  const {
    currentAdded,
    currentUpdates,
    previousAdded,
    previousUpdates,
    follows,
    inReview,
    pendingFeedback,
    pendingSentiment,
    completedSentiment,
  } = useUserHighlights(authStore.userId);

  const cards = useMemo(() => {
    return (
      <>
        <Card className="group relative grid select-none grid-cols-2 items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Sentiments"]} label={"Sentiment Forms"} />
          <div className="flex h-full flex-col items-center">
            <Card.Score
              current={completedSentiment}
              className="mt-11 flex"
              scoreClassname="mr-2 text-[32px] font-[600] text-black"
            />
            <Card.Label label={"Completed this week"} className="mb-2 bg-white" />
          </div>
          <div className="flex h-full flex-col items-center">
            <Card.Score
              current={pendingSentiment}
              className="mt-11 flex"
              scoreClassname="mr-2 text-[32px] font-[600] text-black"
            />
            <Card.Label label={"To complete"} className="mb-2 bg-white" />
          </div>
        </Card>
        <Card className="group relative flex h-full select-none flex-col items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Forwards"]} label={"Companies added this week"} />
          <Card.Score
            current={currentAdded}
            previous={previousAdded}
            className="mt-11 flex"
            scoreClassname="mr-2 text-[32px] font-[600] text-black"
          />
          <Card.Label label={"Compared to last week"} className="mb-2 bg-white" />
        </Card>
        <Card className="group relative flex h-full select-none flex-col items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Feedbacks"]} label={"Companies to close the loop with"} />
          <Card.Score
            current={pendingFeedback}
            className="mt-11 flex"
            scoreClassname="mr-2 text-[32px] font-[600] text-black"
          />
        </Card>
        <Card className="group relative flex h-full select-none flex-col items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Updates"]} label={"Company updates received this week"} />
          <Card.Score
            current={currentUpdates}
            previous={previousUpdates}
            className="mt-11 flex"
            scoreClassname="mr-2 text-[32px] font-[600] text-black"
          />
          <Card.Label label={"Compared to last week"} className="mb-2 bg-white" />
        </Card>
        <Card className="group relative flex h-full select-none flex-col items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Reviews"]} label={"Companies in review"} />
          <Card.Score
            current={inReview}
            className="mt-11 flex"
            scoreClassname="mr-2 text-[32px] font-[600] text-black"
          />
        </Card>
        <Card className="group relative flex h-full select-none flex-col items-center justify-between gap-y-2 transition hover:scale-[1.01]">
          <Card.BoxType className={colorScheme["Follows"]} label={"Companies following"} />
          <Card.Score
            current={follows}
            className="mt-11 flex"
            scoreClassname="mr-2 text-[32px] font-[600] text-black"
          />
        </Card>
      </>
    );
  }, [
    completedSentiment,
    currentAdded,
    currentUpdates,
    follows,
    inReview,
    pendingFeedback,
    pendingSentiment,
    previousAdded,
    previousUpdates,
  ]);

  return (
    <div>
      <div data-cy="dashboard_highlights" className="grid auto-rows-fr grid-cols-2 gap-3 lg:grid-cols-3">
        {cards}
      </div>
    </div>
  );
};
