import { useCallback } from "react";

import { Combobox } from "@headlessui/react";
import Highlighter from "react-highlight-words";

import { classNames } from "@/lib/utils";

import { CompanyImg, Icon } from "@/app/components";
import { START_QUERY_AFTER, Company } from "@/app/hooks";
import { SearchRef, useSearchRef } from "@/app/hooks/use-search-ref";

interface SearchResultProps {
  searchTerm: string;
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  queryRef: SearchRef;
  handleSelect?: (value: Company) => void;
  showEmptyState?: boolean;
}

export const SearchBox = ({
  queryRef,
  searchTerm,
  className = "",
  containerClassName = "",
  loading,
  handleSelect,
  showEmptyState = true,
}: SearchResultProps) => {
  const { result } = useSearchRef(queryRef);

  const onSelect = useCallback(
    (value) => {
      if (handleSelect) handleSelect(value);
    },
    [handleSelect],
  );

  const isEmpty = searchTerm.length > START_QUERY_AFTER && result.length === 0 && !loading;

  if (isEmpty && showEmptyState) {
    return (
      <div className="mt-4 flex size-full items-center justify-center">
        <div>
          <Icon type="LoopClouds" className="mx-auto size-16 text-primary" />
          <p className="mt-4 text-2xl font-semibold text-black">No results found</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`overflow-hidden ${containerClassName}`}>
      <Combobox onChange={onSelect}>
        {result.length > 0 && (
          <Combobox.Options
            static
            className={`no-scrollbar h-full max-h-[300px] transform-gpu scroll-py-3 overflow-y-auto p-3 ${className}`}
          >
            {result.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                data-cy="search-result"
                className={({ active, selected }) =>
                  classNames(
                    "mt-2 flex cursor-default cursor-pointer select-none rounded-xs p-3",
                    (active || selected) && "bg-gray-100",
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex w-full items-center">
                    <div className={classNames("flex h-10 w-10 flex-none items-center justify-center")}>
                      <CompanyImg src={item.image} />
                    </div>
                    <div className="ml-4">
                      <p
                        className={classNames(
                          "text-left text-sm font-medium",
                          active ? "text-gray-900" : "text-gray-700",
                        )}
                      >
                        <Highlighter
                          textToHighlight={item.name}
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          highlightClassName="font-bold bg-transparent"
                        />
                      </p>
                      <p
                        className={classNames(
                          "text-left text-xs font-medium",
                          active ? "text-gray-900" : "text-gray-700",
                        )}
                      >
                        {item.websiteUrl}
                      </p>
                    </div>
                    {(active || selected) && (
                      <Icon type="ArrowRight" width={24} height={24} className="ml-auto text-neutral-400" />
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};
