import React, { Fragment } from "react";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { cn, textGradient } from "@/lib/utils.ts";

import { Icon, ImageWithErrorProfile, Text } from "@/app/components";
import { tabsMobile } from "@/app/components/navbar/header.store";
import { AppVersion } from "@/app/router/app-version";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { OpportunitiesTab, OpportunitiesTabType } from "@/app/screens/opportunities/opportunities.store.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import { Routes } from "@/app/constants";
import { useAuthContext } from "@/app/contexts/auth";

export const MobileDrawerNavigation = () => {
  const { user } = authStore;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { myRoles } = useAuthContext();
  return (
    <div className={"flex size-full flex-1 flex-col"}>
      <Text text={"NAZARE"} className={cn(textGradient("b"), "w-full text-center tracking-[6px]")} type={"subtitle"} />
      <div className={"mb-2 mt-3 flex flex-col items-center"}>
        <ImageWithErrorProfile
          onClick={() => {
            navigate(`${Routes.settings}`);
            globalModalStore.toggleSlideOver({
              isOpen: false,
            });
          }}
          className="ring-2 ring-gray-200 mb-4 flex size-11 items-center justify-center rounded-tremor-small text-center text-[20px]"
          src={user?.avatar}
          alt={user?.initials || ""}
        />
        <Text text={`${user?.firstName} ${user?.lastName}`} className={"text-center text-[14px]"} />
      </div>
      <ul className={"mt-6 space-y-4"}>
        {tabsMobile.map((group, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <div className={cn("w-full py-0.5")}>
                <div className={"border-t border-t-gray-200"} />
              </div>
            )}

            {group.map(({ name, Icon, path, disabled }) => (
              // .filter(({ webOnly }) => !webOnly)
              <li
                key={name}
                onClick={() => {
                  if (path in OpportunitiesTab) {
                    opportunitiesStore.setState({
                      currentTab: {
                        path: path as OpportunitiesTabType,
                        quickView: "all",
                      },
                    });
                  }
                  navigate(path);
                  globalModalStore.toggleSlideOver({
                    isOpen: false,
                  });
                }}
                className={cn(
                  "flex w-full items-center rounded-xs px-4 py-2.5",
                  pathname.includes(path) ? "bg-neutral-100" : "",
                )}
              >
                <a
                  className={cn(
                    pathname.includes(path) ? "font-bold" : "font-semibold",
                    "group flex gap-x-3 text-white",
                  )}
                >
                  <div className={"relative"}>
                    <Icon className={cn(pathname.includes(path) ? "text-secondary" : "text-neutral-400", "size-6")} />
                  </div>
                  <Text
                    className={"flex"}
                    text={name}
                    color={pathname.includes(path) ? "text-black" : "text-neutral-400"}
                  />

                          {myRoles.includes("USER_ROLE_NETWORK") && path === "network" ? (<div className="ml-auto translate-x-[-14px] translate-y-[-8px] text-[12px] font-bold text-[#fe625b]">
                            Beta
                          </div>): null}


                  {pathname.includes(path) && (
                    <div
                      className={cn(
                        "absolute right-0 -mt-1.5 h-10  w-1.5 rotate-180 rounded-r-md bg-gradient-to-t from-primary to-secondary transition-all ease-in-out",
                      )}
                    />
                  )}
                </a>
              </li>
            ))}
          </Fragment>
        ))}
      </ul>

      <button
        className={"flex w-full items-center rounded-xs px-4 mt-auto py-3 ring-none"}
        onClick={() => authStore.logout()}
      >
        <div className={"group flex gap-x-3 text-white"}>
          <div className={"relative"}>
            <Icon type={"LogOut"} className={cn("text-secondary", "size-6")} />
          </div>
          <Text className={"flex"} text={"Log Out"} color={"text-black"} />
        </div>
      </button>

      <div className={"mt-auto flex  flex-col items-center justify-center"}>
        <AppVersion className={"mt-2 text-xs text-neutral-400"} />
      </div>
    </div>
  );
};
