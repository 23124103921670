import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SentimentQueryGetQueryVariables = Types.Exact<{
  id: Types.Scalars["String"]["input"];
  userId: Types.Scalars["UUID"]["input"];
}>;

export type SentimentQueryGetQuery = {
  __typename?: "Query";
  nzrSentimentForm?: {
    __typename?: "NzrSentimentForm";
    id: string;
    analytics?: any | null;
    category?: string | null;
    companyId?: any | null;
    companyName?: string | null;
    createdAt?: any | null;
    createdById?: any | null;
    expiresAt?: any | null;
    fields?: any | null;
    formStatus?: string | null;
    responses?: any | null;
    sentimentSummary?: string | null;
    nzrSentimentFormAssignees: {
      __typename?: "NzrSentimentFormAssigneesConnection";
      nodes: Array<{ __typename?: "NzrSentimentFormAssignee"; id: any }>;
    };
    company?: {
      __typename?: "LgCompany";
      id: any;
      isOpCompany: boolean;
      companyFlags: {
        __typename?: "LgCompanyFlagsConnection";
        items: Array<{
          __typename?: "LgCompanyFlag";
          id: any;
          createdAt: any;
          updatedAt?: any | null;
          createdBy: any;
          companyId: any;
          flag: string;
        }>;
      };
    } | null;
  } | null;
};

export type RequestSentimentFormMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.NewSentimentInput>;
}>;

export type RequestSentimentFormMutation = { __typename?: "Mutation"; sentimentRequest?: any | null };

export type DeclineSentimentFormMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.DeclineSentimentInput>;
}>;

export type DeclineSentimentFormMutation = { __typename?: "Mutation"; sentimentDecline?: any | null };

export type FillSentimentFormMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.FillSentimentInput>;
}>;

export type FillSentimentFormMutation = { __typename?: "Mutation"; sentimentFill?: any | null };

export const SentimentQueryGetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SentimentQueryGet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrSentimentForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "memberId" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "state" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "notEqualTo" },
                                  value: { kind: "StringValue", value: "COMPLETED", block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "companyFlags" },
                        name: { kind: "Name", value: "lgCompanyFlagsByCompanyId" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "deletedAt" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "isNull" },
                                        value: { kind: "BooleanValue", value: true },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "items" },
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "CompanyFlagFragment" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanyFlagFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFlag" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdBy" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "flag" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSentimentQueryGetQuery__
 *
 * To run a query within a React component, call `useSentimentQueryGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentimentQueryGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentimentQueryGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSentimentQueryGetQuery(
  baseOptions: Apollo.QueryHookOptions<SentimentQueryGetQuery, SentimentQueryGetQueryVariables> &
    ({ variables: SentimentQueryGetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>(SentimentQueryGetDocument, options);
}
export function useSentimentQueryGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>(
    SentimentQueryGetDocument,
    options,
  );
}
export function useSentimentQueryGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>(
    SentimentQueryGetDocument,
    options,
  );
}
export type SentimentQueryGetQueryHookResult = ReturnType<typeof useSentimentQueryGetQuery>;
export type SentimentQueryGetLazyQueryHookResult = ReturnType<typeof useSentimentQueryGetLazyQuery>;
export type SentimentQueryGetSuspenseQueryHookResult = ReturnType<typeof useSentimentQueryGetSuspenseQuery>;
export type SentimentQueryGetQueryResult = Apollo.QueryResult<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>;
export const RequestSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RequestSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "NewSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "newSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RequestSentimentFormMutationFn = Apollo.MutationFunction<
  RequestSentimentFormMutation,
  RequestSentimentFormMutationVariables
>;

/**
 * __useRequestSentimentFormMutation__
 *
 * To run a mutation, you first call `useRequestSentimentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSentimentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSentimentFormMutation, { data, loading, error }] = useRequestSentimentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestSentimentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestSentimentFormMutation, RequestSentimentFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestSentimentFormMutation, RequestSentimentFormMutationVariables>(
    RequestSentimentFormDocument,
    options,
  );
}
export type RequestSentimentFormMutationHookResult = ReturnType<typeof useRequestSentimentFormMutation>;
export type RequestSentimentFormMutationResult = Apollo.MutationResult<RequestSentimentFormMutation>;
export type RequestSentimentFormMutationOptions = Apollo.BaseMutationOptions<
  RequestSentimentFormMutation,
  RequestSentimentFormMutationVariables
>;
export const DeclineSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DeclineSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentDecline" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "declineSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeclineSentimentFormMutationFn = Apollo.MutationFunction<
  DeclineSentimentFormMutation,
  DeclineSentimentFormMutationVariables
>;

/**
 * __useDeclineSentimentFormMutation__
 *
 * To run a mutation, you first call `useDeclineSentimentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineSentimentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineSentimentFormMutation, { data, loading, error }] = useDeclineSentimentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineSentimentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineSentimentFormMutation, DeclineSentimentFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineSentimentFormMutation, DeclineSentimentFormMutationVariables>(
    DeclineSentimentFormDocument,
    options,
  );
}
export type DeclineSentimentFormMutationHookResult = ReturnType<typeof useDeclineSentimentFormMutation>;
export type DeclineSentimentFormMutationResult = Apollo.MutationResult<DeclineSentimentFormMutation>;
export type DeclineSentimentFormMutationOptions = Apollo.BaseMutationOptions<
  DeclineSentimentFormMutation,
  DeclineSentimentFormMutationVariables
>;
export const FillSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FillSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FillSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentFill" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fillSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FillSentimentFormMutationFn = Apollo.MutationFunction<
  FillSentimentFormMutation,
  FillSentimentFormMutationVariables
>;

/**
 * __useFillSentimentFormMutation__
 *
 * To run a mutation, you first call `useFillSentimentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFillSentimentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fillSentimentFormMutation, { data, loading, error }] = useFillSentimentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFillSentimentFormMutation(
  baseOptions?: Apollo.MutationHookOptions<FillSentimentFormMutation, FillSentimentFormMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FillSentimentFormMutation, FillSentimentFormMutationVariables>(
    FillSentimentFormDocument,
    options,
  );
}
export type FillSentimentFormMutationHookResult = ReturnType<typeof useFillSentimentFormMutation>;
export type FillSentimentFormMutationResult = Apollo.MutationResult<FillSentimentFormMutation>;
export type FillSentimentFormMutationOptions = Apollo.BaseMutationOptions<
  FillSentimentFormMutation,
  FillSentimentFormMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkDatum",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;
