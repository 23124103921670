import { useRef } from "react";

import { toast } from "react-hot-toast";

import { cn } from "@/lib/utils.ts";

import { ProgressSpinner } from "@/app/components";
import { ProgressCircle } from "@/app/components/progress-circle.tsx";
import { FileUploadFnParam, useFileUpload } from "@/app/hooks/use-file-upload.ts";

type FileUploadProps = {
  uploadFn: FileUploadFnParam;
  label?: string;
  className?: string;
  options?: {
    prefix?: string;
    messages?: {
      success?: string;
      error?: string;
    };
  };
  onSuccess?: () => void;
};

export const FileUpload = ({ uploadFn, className = "", options, onSuccess, label = "Add File" }: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleUpload, loading, uploadProgress } = useFileUpload({
    uploadFn,
    prefix: options?.prefix,
  });

  const handleFileChange = (event) => {
    handleUpload(event.target.files[0])
      .then(() => {
        toast.success(options?.messages?.success ?? "File has been uploaded", {
          position: "top-right",
        });
        onSuccess?.();
      })
      .finally(() => {
        // clear input value because otherwise onChange won't be triggerred when uploading the same file again
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      });
  };

  const disabled = loading || uploadProgress !== null;

  return (
    <div className={cn(className, "relative overflow-hidden rounded-sm")}>
      <input
        data-cy="upload-input"
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        id="file-input"
        className="hidden"
        disabled={disabled}
      />
      <label
        htmlFor="file-input"
        className={cn(
          "flex cursor-pointer select-none  bg-black px-3 py-2 text-sm font-semibold text-white hover:bg-neutral-800 active:bg-neutral-600",
          disabled && "pointer-events-none bg-neutral-200",
        )}
      >
        {label}
      </label>
      {uploadProgress !== null && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center bg-white/50 backdrop-blur-sm">
          <ProgressCircle className="size-5" progress={uploadProgress} />
        </div>
      )}
      {loading && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center bg-white/50 backdrop-blur-sm">
          <ProgressSpinner className="size-5" />
        </div>
      )}
    </div>
  );
};
