import React, { useEffect, useMemo, useState } from "react";

import { useBreakpoints } from "@/lib/hooks";

import { SentimentHistory } from "@/app/components/charts/sentiment-boxes.tsx";
import {
  SentimentAttribute,
  SentimentFieldSelector,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-selector.tsx";
import {
  SentimentState,
  useSentimentContext,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { SentimentRadarChart } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-radar-chart.tsx";

const attrOrder = ["excitement", "vision", "market", "product", "team", "fundraising", "timing", "fit", "opportunity"];

export const SentimentBoxPlot = ({ sentimentData }) => {
  const isLoading = false; // todo figure out why it wasn't reassigned
  const { setState } = useSentimentContext();
  const [referenceField, setReferenceField] = useState<SentimentAttribute>("excitement_opinion_scale");

  // stores value of a sentiment selected on the chart
  const [selectedSentiment, setSelectedSentiment] = useState(null);

  useEffect(() => {
    if (isLoading) {
      setState(SentimentState.loading);
    } else {
      const dataLen = sentimentData?.length || 0;
      if (dataLen === 0) {
        setState(SentimentState.no_data);
      } else {
        setState(SentimentState.has_data);
      }
    }
  }, [sentimentData, isLoading, setState]);

  const { radarChartData, tooltipChartData } = useMemo(() => {
    const firstNonNullForm = sentimentData?.find((form) => form?.responses?.length > 0);

    const radarChartData = attrOrder.map((item) => ({
      label: item,
      value: firstNonNullForm?.analytics?.[`${item}_opinion_scale`]?.mean?.toFixed(2),
    }));

    const tooltipForm = sentimentData.find((form) => form.id === selectedSentiment);
    const tooltipChartData = selectedSentiment
      ? attrOrder.map((item) => ({
          label: item,
          value: tooltipForm?.analytics[`${item}_opinion_scale`]?.mean?.toFixed(2),
        }))
      : null;

    const ReturnKey = Object.keys(
      firstNonNullForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    const ReturnKeyCompare = Object.keys(
      tooltipForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    return { radarChartData, tooltipChartData, ReturnKey, ReturnKeyCompare };
  }, [sentimentData, selectedSentiment]);

  const { isTablet } = useBreakpoints();

  return (
    <div className="relative flex w-full flex-col">
      <div className="mb-2 flex items-center">
        <SentimentFieldSelector onChange={setReferenceField} value={referenceField} />
      </div>
      <div className="flex h-[33dvh] max-w-full grow items-start lg:min-h-[400px] ">
        <>
          <SentimentHistory
            sentiment={sentimentData}
            referenceField={referenceField}
            setActiveTooltip={setSelectedSentiment}
          />
        </>
      </div>
    </div>
  );
};
