import React, { CSSProperties, useContext } from "react";

import { curveMonotoneX } from "@visx/curve";
import { GlyphDot } from "@visx/glyph";
import { LinearGradient } from "@visx/gradient";
import { PatternLines } from "@visx/pattern";
import { useParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { BoxPlot } from "@visx/stats";
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,
  DataContext,
  GlyphProps,
  lightTheme,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import cx from "classnames";
import { quantileSorted } from "d3-array";
import { curveCardinal, line } from "d3-shape";
import { DateTime } from "luxon";

import { useBreakpoints } from "@/lib/hooks";
import { cn, extractFormCategory } from "@/lib/utils.ts";

import { colors } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-selector.tsx";

const accesors = {
  xAccessor: (data) => data.date,
  yAccessor: (data) => data.metric,
};

function ViolinPlot({
  left = 0,
  top = 0,
  className,
  data,
  width = 10,
  count = (d) => d.count,
  value = (d) => d.value,
  valueScale,
  horizontal,
  children,
  ...restProps
}) {
  const center = (horizontal ? top : left) + width / 2;
  const binCounts = data.map((bin) => count(bin));
  const widthScale = scaleLinear<number>({
    range: [0, width / 2],
    round: true,
    domain: [0, Math.max(...binCounts)],
  });

  let path = "";

  if (horizontal) {
    const topCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center - (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const bottomCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center + (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const topCurvePath = topCurve(data) || "";
    const bottomCurvePath = bottomCurve([...data].reverse()) || "";
    path = `${topCurvePath} ${bottomCurvePath.replace("M", "L")} Z`;
  } else {
    const rightCurve = line()
      .x((d) => center + (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const leftCurve = line()
      .x((d) => center - (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const rightCurvePath = rightCurve(data) || "";
    const leftCurvePath = leftCurve([...data].reverse()) || "";
    path = `${rightCurvePath} ${leftCurvePath.replace("M", "L")} Z`;
  }
  if (children) return <>{children({ path })}</>;
  return <path className={cx("visx-violin", className)} d={path} {...restProps} />;
}

const renderGlyph = ({
  x,
  y,
  datum,
  size,
  onPointerMove,
  onPointerOut,
  onPointerUp,
}: GlyphProps<{ x: string; y: number }>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { xScale, yScale, innerWidth } = useContext(DataContext) || {};
  const width = 22;
  const violinWidth = width * 1.3;

  let yScaleCopy = function (d) {
    return yScale(d) - yScale(datum.metric);
  };

  yScaleCopy = Object.assign(yScaleCopy, { range: yScale.range });

  return (
    <>
      {datum.bins.length && (
        <>
          <BoxPlot
            min={datum.boxPlot.min}
            max={datum.boxPlot.max}
            left={-width / 2}
            firstQuartile={datum.boxPlot.q25}
            thirdQuartile={datum.boxPlot.q75}
            median={datum.boxPlot.q50}
            boxWidth={width}
            fill="url(#area-gradient)"
            fillOpacity={0.2}
            stroke={colors.primary.DEFAULT}
            strokeWidth={1}
            valueScale={yScaleCopy}
          />
        </>
      )}
      <GlyphDot
        left={x}
        top={y}
        fill="url(#area-gradient)"
        stroke={"white"}
        strokeWidth={1}
        r={size / 2 + 1}
        {...handlers}
      />
    </>
  );
};

function SentimentChart({ data, analytics, width, height, setActiveTooltip }) {
  const { isLaptop } = useBreakpoints();
  return (
    <XYChart
      xScale={{ type: "band" }}
      yScale={{ type: "linear", domain: [analytics.min - 0.5, analytics.max + 0.5], zero: false, round: true }}
      theme={lightTheme}
      width={width}
      margin={{ top: 20, right: 20, bottom: 20, left: 30 }}
      height={height}
      onPointerDown={({ datum }) => {
        const item = datum?.form;
        opportunityModal({
          id: `sentiment_details`,
          platform: window.innerWidth <= 976 ? "mobile" : "web",
          action: "Sentiment",
        });

        opportunitiesStore.setModalState("sentiment_details", {
          form: item,
        });
      }}
    >
      <LinearGradient id="area-gradient" from={colors.secondary.DEFAULT} to={colors.primary.DEFAULT} />
      <PatternLines
        id="violinLines"
        height={3}
        width={3}
        stroke="#E9366B"
        strokeWidth={1}
        // fill="rgba(0,0,0,0.3)"
        orientation={["horizontal"]}
      />
      <AnimatedGrid
        key={`grid-xy`} // force animate on update
        rows={false}
        columns={true}
        strokeDasharray={"8"}
        lineStyle={{ stroke: colors.neutral[400], strokeWidth: 1 }}
      />
      <AnimatedAxis
        key={`time-axis`}
        orientation="bottom"
        axisLineClassName={"stroke-neutral-400"}
        strokeWidth={1}
        strokeDasharray={"8"}
        numTicks={isLaptop ? 6 : 3}
        hideTicks={true}
        tickLabelProps={{
          className: cn("text-xss font-semibold text-neutral-400"),
        }}
        tickFormat={(value) => {
          return normaliseDate(value) as Parameters<typeof legendScale>[0];
        }}
      />
      <AnimatedAxis
        key={`right-axis`}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{ className: cn("text-xss font-semibold") }}
        orientation={"left"}
      />
      {/*{data.map((datum) => (*/}
      {/*  <ViolinGlyph datum={datum} />*/}
      {/*))}*/}

      <AnimatedLineSeries
        dataKey="line"
        strokeWidth={3.5}
        stroke="url(#area-gradient)"
        fillOpacity={0.9}
        data={data}
        {...accesors}
        curve={curveMonotoneX}
      />
      <AnimatedGlyphSeries renderGlyph={renderGlyph} dataKey="line" data={data} {...accesors} />

      <Tooltip
        applyPositionStyle={true}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showHorizontalCrosshair
        showSeriesGlyphs
        horizontalCrosshairStyle={{ strokeDasharray: "8" }}
        renderGlyph={(props) => (
          <>
            <GlyphDot {...props} r={4} fill="url(#area-gradient)" stroke={"white"} />
          </>
        )}
        renderTooltip={({ tooltipData, showTooltip }) => {
          setActiveTooltip(tooltipData.nearestDatum.datum.id);

          return (
            <div className={""}>
              <p
                className={"text-xss font-semibold text-neutral-700"}
              >{`${extractFormCategory(tooltipData.nearestDatum.datum.form)}`}</p>
              <p className={"text-xss font-semibold text-neutral-700"}>
                {normaliseDate(accesors.xAccessor(tooltipData.nearestDatum.datum))}
              </p>
              <p className="mt-2">{accesors.yAccessor(tooltipData.nearestDatum.datum)?.toFixed(2)}</p>
              <p>{tooltipData.nearestDatum.datum.people.join(", ")}</p>
            </div>
          );
        }}
      />
    </XYChart>
  );
}

export function SentimentHistory({
  sentiment,
  referenceField,
  className,
  style,
  setActiveTooltip,
}: {
  sentiment: any; // todo
  referenceField: SentimentAttribute;
  className?: string;
  style: CSSProperties;
  setActiveTooltip: any; // todo
}) {
  const chronologicalForms = [...sentiment].filter(({ responses }) => responses?.length > 0).reverse();

  const data = chronologicalForms.map((form) => {
    const responseArray = form?.responses
      ?.map((response) => Number(response.answers.find((answer) => answer.field_id === referenceField)?.value))
      .toSorted((a, b) => a - b);

    const min = Math.min(...responseArray);
    const max = Math.max(...responseArray);

    let bins = Object.entries(responseArray.reduce((prev, curr) => ({ ...prev, [curr]: 1 + (prev[curr] || 0) }), {}));
    bins =
      bins.length === 1
        ? []
        : bins
            .toSorted((a, b) => Number(a[0]) - Number(b[0]))
            .map(([value, count]) => ({ value: Number(value), count: count + 1 }))
            // .concat([
            //   { value: min - 0.3, count: 0 },
            //   { value: max + 0.3, count: 0 },
            // ])
            .toSorted((a, b) => Number(a.value) - Number(b.value));
    console.log(form.id, bins);

    // const gapSize = 0.5;
    // for (let i = 0; i < bins.length - 1; i++) {
    //   const current = bins[i];
    //   const next = bins[i + 1];
    //   const gap = next.value - current.value;
    //   let currentCount = 1;
    //
    //   if (gap > gapSize) {
    //     const newEntries = [];
    //     for (let j = current.value + gapSize; j < next.value; j += gapSize) {
    //       newEntries.push({ value: j, count: currentCount });
    //       currentCount -= 0.1;
    //     }
    //     bins.splice(i + 1, 0, ...newEntries); // Insert new entries to fill the gap
    //     i += newEntries.length; // Adjust the index to account for new entries
    //   }
    // }

    return {
      metric: form?.analytics?.[referenceField]?.mean,
      id: form?.id,
      form,
      date: DateTime.fromISO(form.createdAt).toFormat("yyyy-MM-dd"),
      formResults: form?.responses?.map((response) =>
        Number(response.answers.find((answer) => answer.field_id === referenceField)?.value),
      ),
      boxPlot: {
        min,
        q25: quantileSorted(responseArray, 0.25),
        q50: quantileSorted(responseArray, 0.5),
        q75: quantileSorted(responseArray, 0.75),
        max,
      },
      bins,
      people: Object.keys(form.analytics.person).filter((item) => item != "id" && item != "other"),
    };
  });

  const analytics = {
    min: Math.min(
      ...(data.map((datum) => Math.min(...(datum.formResults || [0]))).filter((item) => !isNaN(item)) || []),
    ),
    max: Math.max(
      ...(data.map((datum) => Math.max(...(datum.formResults || [10]))).filter((item) => !isNaN(item)) || []),
    ),
  };

  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });

  return (
    <div ref={parentRef} className={cn("h-[33dvh] overflow-hidden lg:min-h-[400px]", className)} style={style}>
      <SentimentChart
        data={data}
        analytics={analytics}
        width={width}
        height={height}
        setActiveTooltip={setActiveTooltip}
      />
    </div>
  );
}
