import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button, Card } from "@/app/components";
import { Routes } from "@/app/constants";
import { EmptySection, OpportunityTable } from "@/app/screens/opportunities/opportunity/components";
import { MetricsXYChart } from "@/app/screens/opportunities/opportunity/components/metrics/metrics-xychart.tsx";
import { useMetrics } from "@/app/screens/opportunities/opportunity/components/metrics/use-metrics.tsx";

export const MetricsSection = ({ company }) => {
  const navigate = useNavigate();
  const {
    columns,
    tabularData,
    graphData,
    maximised,
    showEmpty,
    setMaximised,
    setSelectedMetric,
    metricsSelectList,
    selectedMetric,
    legendScaleOptions,
    navigateToMetricsExtraction,
  } = useMetrics(company);

  console.log("kek", showEmpty);
  if (showEmpty) {
    return (
      <EmptySection
        text={"No metrics recorded"}
        Action={
          <Button
            text={"Add Metrics"}
            className={"mt-5"}
            onClick={() => navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: company.id } })}
          />
        }
      />
    );
  }
  return (
    <Card className="p-0 pb-3">
      <MetricsXYChart
        data1={graphData}
        companyId={company.id}
        maximised={[maximised, setMaximised]}
        selectedMetric={[selectedMetric, setSelectedMetric]}
        metricsSelectList={metricsSelectList}
        legendScaleOptions={legendScaleOptions}
        navigateToMetricsExtraction={navigateToMetricsExtraction}
      />
      <OpportunityTable<(typeof tabularData)[0]>
        columns={columns}
        enableSorting={false}
        tableOptions={{
          density: "extraDense",
          elementOptions: {
            row: {
              className: "hover:!bg-gray-50 group",
            },
          },
        }}
        data={tabularData}
        scrollXToLastItem={true}
        fnOnRowClick={(row) => {
          setSelectedMetric(row.Type);
        }}
        containerClassName={cn(
          "mt-4 border-t border-neutral-200 transition-all duration-500 ease-in-out",
          maximised ? "hidden" : "",
        )}
        pageSize={7}
        headerClassName={"w-full"}
        enableColumnPinning={true}
      />
    </Card>
  );
};
