import React, { useState } from "react";

import { InitialTableState } from "@tanstack/table-core";

import { cn } from "@/lib/utils.ts";

import { Card, Text } from "@/app/components";
import { FileUpload } from "@/app/components/file-upload/file-upload.tsx";
import { LoadingOverLay } from "@/app/components/loading-overlay.tsx";
import { OpportunityTable } from "@/app/screens/opportunities/opportunity/components/table.tsx";

import { useAttachmentUpload } from "./use-attachment-upload.ts";
import { useAttachmentsColumns } from "./use-attachments-columns.tsx";
import { useNazareAttachments } from "./use-nazare-attachments";

const tableInitialState: InitialTableState = {
  sorting: [
    {
      id: "date",
      desc: true,
    },
  ],
};

export const Attachments = () => {
  const [pendingRefetch, setPendingRefetch] = useState(false);
  const [nazareAttachments, , refetch] = useNazareAttachments();

  const { options: fileUploadOptions, uploadFn } = useAttachmentUpload();

  const columns = useAttachmentsColumns();

  // refetch attachments with a delay since it takes couple seconds for lambda to update file status
  const handleRefetchAttachments = () => {
    setPendingRefetch(true);
    setTimeout(() => {
      refetch().finally(() => {
        setPendingRefetch(false);
      });
    }, 5000);
  };

  const UploadComponent = (
    <FileUpload options={fileUploadOptions} uploadFn={uploadFn} onSuccess={handleRefetchAttachments} />
  );

  if (!nazareAttachments.length) {
    return (
      <div className="relative flex h-[200px] w-full flex-col items-center justify-center gap-5 rounded-lg bg-white">
        {pendingRefetch && <LoadingOverLay />}
        <div className={cn("flex flex-col items-center justify-center gap-5", pendingRefetch && "hidden")}>
          <Text text="No Attachments Added" type={"title"} color={"text-neutral-300"} />
          {UploadComponent}
        </div>
      </div>
    );
  }

  return (
    <Card className="relative p-0">
      {pendingRefetch && <LoadingOverLay />}
      <OpportunityTable
        data={nazareAttachments}
        ActionButton={UploadComponent}
        columns={columns}
        enableGlobalFilter={true}
        containerClassName="p-3 md:p-5"
        headerClassName="flex-wrap"
        initialState={tableInitialState}
      />
    </Card>
  );
};
