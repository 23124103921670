import { GlyphDot } from "@visx/glyph";
import { LinearGradient } from "@visx/gradient";
// For the moment being we're not going to do comparison anymore so scaleLinear is not needed
import {
  // any of these can be non-animated equivalents
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  GlyphProps,
  GlyphSeries,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { AnimatedAxisProps } from "@visx/xychart/lib/components/axis/AnimatedAxis";
import { curveMonotoneX } from "d3-shape";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { colors } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { formatNumber } from "@/app/screens/opportunities/opportunity/components";

type Data = { x: string | number; y: number | string | boolean; source?: string };

export const XYDashboard = ({
  data1,
  leftAxisProps = {},
  // data2 = example_data2,
  // rightAxisProps = {},
  maximised = false,
}: {
  data1: { label: string; value: Data };
  leftAxisProps?: Partial<AnimatedAxisProps<any>>;
  data2?: { label: string; value: Data };
  rightAxisProps?: Partial<AnimatedAxisProps<any>>;
  maximised: boolean;
}) => {
  const { isLaptop } = useBreakpoints();
  return (
    <div className={cn("relative pl-3 ", maximised ? "mt-6" : "mt-3")}>
      <XYChart
        height={maximised ? 700 : 400}
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
        margin={{ top: 25, right: isLaptop ? 10 : 20, bottom: 40, left: 50 }}
      >
        {/*BACKGROUND*/}
        <>
          <AnimatedAxis
            orientation="bottom"
            axisLineClassName={"stroke-neutral-400"}
            strokeWidth={1}
            hideTicks={true}
            strokeDasharray={"8"}
            numTicks={isLaptop ? 8 : 3}
            animationTrajectory={maximised ? "min" : "max"}
            tickLabelProps={{
              className: cn("text-xss font-semibold text-neutral-400"),
            }}
            tickFormat={(value) => {
              return normaliseDate(value, isLaptop ? undefined : "LLL YYY") as Parameters<any>[0];
            }}
          />
          <AnimatedGrid
            rows={false}
            columns={true}
            strokeDasharray={"8"}
            animationTrajectory={maximised ? "min" : "max"}
            lineStyle={{ stroke: colors.neutral[400], strokeWidth: 1 }}
          />
        </>
        {/*FIRST DATA SET AND CORRESPONDING ITEMS*/}
        <>
          <AnimatedAxis
            orientation="left"
            strokeDasharray={"8"}
            tickLength={4}
            dataKey="data1"
            animationTrajectory={maximised ? "min" : "max"}
            tickLabelProps={{ className: cn("text-xss font-semibold") }}
            {...leftAxisProps}
          />
          <AnimatedLineSeries<Data[]>
            dataKey="data1"
            strokeWidth={3.5}
            stroke="url(#area-gradient)"
            curve={curveMonotoneX}
            data={data1.value}
            {...accessors}
            fillOpacity={0.9}
          />
          <GlyphSeries<Data[]> dataKey="data1" data={data1.value} renderGlyph={renderGlyph} {...accessors} />
          <LinearGradient id="area-gradient" from={colors.primary.DEFAULT} to={colors.secondary.DEFAULT} />
        </>
        {/*SECOND DATA SET AND CORRESPONDING ITEMS*/}
        <>
          {/*<AnimatedAxis*/}
          {/*  orientation="right"*/}
          {/*  tickFormat={(value) => {*/}
          {/*    return value;*/}
          {/*  }}*/}
          {/*  {...rightAxisProps}*/}
          {/*/>*/}
          {/*  <AnimatedLineSeries*/}
          {/*    dataKey="data2"*/}
          {/*    strokeWidth={3.5}*/}
          {/*    stroke={colors.neutral[500]}*/}
          {/*    curve={curveMonotoneX}*/}
          {/*    data={data2}*/}
          {/*    {...accessors}*/}
          {/*  />*/}
          {/*  <GlyphSeries*/}
          {/*    dataKey="data2"*/}
          {/*    data={data2}*/}
          {/*    color={colors.primary.DEFAULT}*/}
          {/*    renderGlyph={renderGlyph}*/}
          {/*    {...accessors}*/}
          {/*  />*/}
        </>

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showHorizontalCrosshair
          horizontalCrosshairStyle={{ strokeDasharray: "8" }}
          renderGlyph={(props) => {
            const { source } = props.datum;
            return (
              <>
                <GlyphDot {...props} r={6} fill={`url(#area-gradient-glyph-${source})`} stroke={"white"} />
              </>
            );
          }}
          renderTooltip={({ tooltipData }) => {
            let value = accessors.yAccessor(tooltipData.nearestDatum.datum);
            if (typeof value === "number") {
              value = formatNumber(accessors.yAccessor(tooltipData.nearestDatum.datum));
            }
            if (typeof value === "boolean") {
              value = value ? "Yes" : "No";
            }
            return (
              <div className={""}>
                <p
                  className={"text-xss font-semibold text-neutral-700"}
                >{`${data1.label} on ${normaliseDate(accessors.xAccessor(tooltipData.nearestDatum.datum))}`}</p>
                <p className={"text-xss font-semibold text-neutral-700"}>
                  Source {tooltipData.nearestDatum.datum.source}
                </p>
                <p className={"mt-2"}>{value}</p>
              </div>
            );
          }}
        />
      </XYChart>
    </div>
  );
};

const accessors = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
};

const renderGlyph = ({
  x,
  y,
  datum: { source },
  size,
  onPointerMove,
  onPointerOut,
  onPointerUp,
}: GlyphProps<{ x: string; y: number; source?: string }>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };

  return (
    <>
      <GlyphDot
        left={x}
        top={y}
        fill={`url(#area-gradient-glyph-${source})`}
        stroke={"white"}
        strokeWidth={2}
        r={size / 2 + 1}
        {...handlers}
      />
      <LinearGradient
        id="area-gradient-glyph-Manual-Entry"
        from={colors.primary.DEFAULT}
        to={colors.secondary.DEFAULT}
      />
      <LinearGradient id="area-gradient-glyph-Dealroom" from={"#69b1ff"} to={"#1677ff"} />
    </>
  );
};
