import React, { createContext, Dispatch, useContext, useState } from "react";

export enum SentimentTab {
  Chart = "chart",
  Table = "table",
  Bar = "bar",
}

export enum SentimentState {
  "initial",
  "loading",
  "has_data",
  "no_data",
}

type SentimentContextState = {
  activeTab: SentimentTab;
  setActiveTab: Dispatch<SentimentTab>;
  state: SentimentState;
  setState: Dispatch<SentimentState>;
};

const SentimentContext = createContext<SentimentContextState>(null as any);

export const SentimentContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState<SentimentTab>(SentimentTab.Chart);
  const [state, setState] = useState<SentimentState>(SentimentState.initial);

  const value: SentimentContextState = {
    activeTab,
    setActiveTab,
    state,
    setState,
  };

  return <SentimentContext.Provider value={value}>{children}</SentimentContext.Provider>;
};

export const useSentimentContext = (): SentimentContextState => {
  const sentimentContext = useContext(SentimentContext);

  if (!sentimentContext) {
    throw new Error("Must be used inside SentimentContextProvider");
  }

  return sentimentContext;
};
