import React from "react";

import { motion } from "framer-motion";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { ImageWithErrorFallback, Text } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar/index.ts";
import { usePageNav } from "@/app/components/page-nav/page-nav-provider.tsx";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import {
  OpportunityActions,
  OpportunityActionsMobile,
} from "@/app/screens/opportunities/opportunity/components/actions/actions.tsx";
import { SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components/social-media-icons.tsx";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";

import { useSocialMediaIcons } from "../use-opportunity.tsx";

type OpportunityHeaderStickyProps = {
  company: OpportunityCompany;
};

export const OpportunityHeaderSticky = observer(({ company }: OpportunityHeaderStickyProps) => {
  const { activeElements } = usePageNav();
  const isOpen = !activeElements.includes("actions");

  const socialMediaIcons = useSocialMediaIcons(company);
  const { isOpen: isSidenavOpen } = globalModalStore.navbarState;
  const navigate = useNavigate();

  if (!company) {
    return null;
  }

  return (
    <motion.div
      className={cn(
        "flex items-center space-x-4 bg-white bg-opacity-[/80] px-5 pl-11 shadow-xs",
        // positioning:
        // -ml-4 is needed because there's a rounded corner on the sidenav and we need to move the header under the sidenav
        // w-[calc(100%+1rem)] is used to make header a bit wider to compensate the -ml-4 shift
        "fixed left-0 top-0 z-20 -ml-6  w-[calc(100%+1.5rem)]",
        // there's a weird bug when using sticky with a sidenav. The header jumps breaking the layout thus using fixed
        "lg:fixed lg:ml-0 lg:w-full lg:px-6 lg:pl-28",
        // we want to shift elements when sidebar is open. 296px = 256px(sidebar width) + 40px(margin left)
        isSidenavOpen && "lg:pl-[296px]",
        "backdrop-blur",
        !isOpen ? "overflow-hidden" : "",
      )}
      variants={{
        open: {
          height: 72,
        },
        closed: {
          height: 0,
        },
      }}
      initial={"closed"}
      animate={isOpen ? "open" : "closed"}
      transition={{ duration: 0.1, easeInOut: true }}
    >
      <BreadCrumbs
        title={"Back"}
        action={() => {
          navigate(-1);
        }}
        elementProps={{
          title: { className: "hidden md:block text-sm" },
          icon: {
            className: "w-4 h-4",
          },
        }}
      />
      <ImageWithErrorFallback
        src={company.image}
        alt={company.name}
        iconClassName={"rounded-md p-2"}
        className="flex size-10 shrink-0 rounded-md border border-neutral-300"
      />

      <div>
        <Text text={company.name} type={"h5"} weight={"semibold"} className="truncate" />
      </div>

      <SocialMediaIcons
        icons={socialMediaIcons}
        iconClassName="h-4 w-4 text-neutral hover:text-[#0072b1] cursor-pointer"
        containerClassName={"ml-3 space-x-5 hidden lg:flex mr-2"}
      />

      <div className="!ml-auto">
        <OpportunityActions company={company} className="hidden pt-0 lg:flex lg:pr-1 2xl:pr-4" />
        <OpportunityActionsMobile company={company} className="ml-4 lg:hidden" />
      </div>
    </motion.div>
  );
});
