import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetFileUrlQueryVariables = Types.Exact<{
  filePath: Types.Scalars["String"]["input"];
}>;

export type GetFileUrlQuery = { __typename?: "Query"; responseGetFilePresignedUrl?: string | null };

export const GetFileUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFileUrl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filePath" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "responseGetFilePresignedUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "file" },
                value: { kind: "Variable", name: { kind: "Name", value: "filePath" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetFileUrlQuery__
 *
 * To run a query within a React component, call `useGetFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUrlQuery({
 *   variables: {
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useGetFileUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileUrlQuery, GetFileUrlQueryVariables> &
    ({ variables: GetFileUrlQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileUrlQuery, GetFileUrlQueryVariables>(GetFileUrlDocument, options);
}
export function useGetFileUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileUrlQuery, GetFileUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileUrlQuery, GetFileUrlQueryVariables>(GetFileUrlDocument, options);
}
export function useGetFileUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFileUrlQuery, GetFileUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFileUrlQuery, GetFileUrlQueryVariables>(GetFileUrlDocument, options);
}
export type GetFileUrlQueryHookResult = ReturnType<typeof useGetFileUrlQuery>;
export type GetFileUrlLazyQueryHookResult = ReturnType<typeof useGetFileUrlLazyQuery>;
export type GetFileUrlSuspenseQueryHookResult = ReturnType<typeof useGetFileUrlSuspenseQuery>;
export type GetFileUrlQueryResult = Apollo.QueryResult<GetFileUrlQuery, GetFileUrlQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NetworkDatum",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;
